<template>
  <div class="material__order__wrapper">
    <div class="item_title">
      <div class="item_titleline"></div>
      资料订单
    </div>
    <div class="content">
      <div class="recruit_tab" style="margin-bottom: 24px">
        <a-row>
          <a-col :span="5">
            <div
              class="recruit_tabItem"
              :class="[val == 1 ? 'active_recruit_tabItem' : '']"
              style="border-right: 0"
              @click="getTab('1')"
            >
              全部
            </div>
          </a-col>
          <a-col :span="5">
            <div class="recruit_tabItem" :class="[val == 2 ? 'active_recruit_tabItem' : '']" @click="getTab('2')">
              待付款
            </div>
          </a-col>
          <a-col :span="5">
            <div class="recruit_tabItem" :class="[val == 3 ? 'active_recruit_tabItem' : '']" @click="getTab('3')">
              待评价
            </div>
          </a-col>
        </a-row>
      </div>

      <div class>
        <p-table
          ref="table"
          :scroll="{ y: 0 }"
          extraHeight="450"
          :columns="columns"
          :sourceData="handleGetData"
          rowKey="orderNo"
          download
          fileName="资料订单"
        >
          <!-- <a-button
            type="primary"
            slot="headerLeft"
            v-if="val != 3"
            :disabled="!selectedRows.length"
          >合并付款</a-button> -->
          <template slot="titles" slot-scope="text, record">
            <div class="custom_cell">
              <div class="custom">
                <div class="left">
                  <img :src="record.thumbImg" />
                </div>
                <div class="right">
                  <div>{{ text }}</div>
                  <div>资料类型： {{ record.extName }}</div>
                </div>
              </div>
            </div>
          </template>
          <template slot="operation" slot-scope="text, record">
            <a-button
              type="link"
              v-if="record.status != 1"
              @click="handleWeChatPay(record.orderNo, record.createdTime)"
            >立即付款</a-button
            >
            <a-button
              type="link"
              v-if="record.status == 1 && record.evaluationStatus == 0"
              @click="Evaluate(record)"
            >立即评价</a-button
            >
            <a-button
              type="link"
              v-if="record.status == 1 && record.evaluationStatus == 1"
              @click="getEvaluateDetail(record)"
            >查看评价</a-button
            >
          </template>
        </p-table>
      </div>
    </div>
    <!-- 二维码付款 -->
    <payZf ref="payZf"/>
    <!-- 订单评价 -->
    <a-modal
      v-model="isEvaluate"
      cancelText="取消"
      okText="确定"
      title="订单评价"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <div class="evaluate_title">对资料的评价</div>
      <a-row style="text-align: center;">
        <a-col :span="24">
          <a-rate v-model="rate" :tooltips="desc" />
          <span class="ant-rate-text">{{ desc[rate - 1] }}</span>
        </a-col>
      </a-row>
      <a-divider />
      <div class="evaluate_title">对本次购买商品的标签评价</div>
      <a-row :gutter="6">
        <a-col :span="4" v-for="item in typeList" :key="item.id">
          <div
            :class="{
              evaluate_tag: true,
              actived: typeSelect.some((key) => key === item.id),
            }"
            @click="handleTypeClick(item.id)"
          >
            {{ item.label }}
          </div>
        </a-col>
      </a-row>
      <a-divider />
      <div class="evaluate_title">对订单的评价</div>
      <a-row :gutter="6">
        <a-textarea v-model="evaluation" placeholder="请输入评价内容" :auto-size="{ minRows: 3, maxRows: 5 }" />
      </a-row>
    </a-modal>
    <!-- 查看评价 -->
    <a-modal
      v-model="isEvaluate1"
      cancelText="取消"
      okText="确定"
      title="查看评价"
      @ok="handleOk1"
      @cancel="handleCancel1"
    >
      <div class="evaluate_title">对资料的评价</div>
      <a-row type="flex" justify="center">
        <a-col :span="12">
          <a-rate v-model="rate" :tooltips="desc" :disabled="disabled" />
          <span class="ant-rate-text">{{ desc[rate - 1] }}</span>
        </a-col>
      </a-row>
      <a-divider />
      <div class="evaluate_title">对本次购买商品的标签评价</div>
      <a-row :gutter="6">
        <a-col :span="4" v-for="item in typeList1" :key="item">
          <div class="evaluate_tag">{{ item }}</div>
        </a-col>
      </a-row>
      <a-divider />
      <div class="evaluate_title">对订单的评价</div>
      <a-row :gutter="6">
        <a-textarea
          v-model="evaluation"
          placeholder="请输入评价内容"
          :auto-size="{ minRows: 3, maxRows: 5 }"
          :disabled="disabled"
        />
      </a-row>
    </a-modal>
  </div>
</template>

<script>
import { getMyDataOrder, updateEvaluation, getEvaluationDetail } from '@/api/learnMsg/index.js'
	import { getTypeDetail } from '@/api/materialShop'
import payZf from './zf.vue'
import moment from 'moment'
const columns = [
  {
    title: '序号',
    align: 'left',
    width: 60,
    customRender: (t, r, i) => i + 1
  },
  {
    title: '订单编号',
    download: true,
    align: 'left',
    dataIndex: 'orderNo'
  },
  {
    title: '资料名称',
    download: true,
    align: 'left',
    width: 350,
    dataIndex: 'title', // detailsImg // extName
    scopedSlots: { customRender: 'titles' }
  },
  {
    title: '订单金额',
    align: 'left',
    download: true,
    dataIndex: 'totalAmount'
  },
  {
    title: '订单生成时间',
    download: true,
    align: 'left',
    dataIndex: 'createdTime',
    customRender: (text) => moment(text).format('YYYY/MM/DD HH:mm:ss')
  },
  {
    title: '订单状态',
    download: true,
    align: 'left',
    dataIndex: 'status',
    customRender: (text) => (text == 0 ? '待付款' : '已完结')
  },
  {
    title: '操作',
    align: 'left',
    dataIndex: 'operation',
    scopedSlots: { customRender: 'operation' }
  }
]

export default {
  name: 'Index',
  components: {
    payZf
  },
  data () {
    return {
      zfType: 1,
      title: '微信扫描上方二维码，完成支付后，可在“控制台-学习资料”中查看购买的资料。',
      outTradeNo: null,
      codeUrl: '',
      QRCode: false,
      loading: false,
      // 查看
      typeList1: [],
      // 修改
      typeList: [],
      typeSelect: [],
      rate: 5,
      evaluation: '',
      columns,
      visible: false,
      val: 1,
      isEvaluate: false,
      isEvaluate1: false,
      selectedRowKeys: [],
      selectedRows: [],
      row: {},
      disabled: false,
      totalCount: 0,
      desc: ['非常不满意', '不满意', '一般', '满意', '非常满意']
    }
  },
  watch: {
    // 监听数值变化
    second: {
      handler (newVal) {
        this.num(newVal)
      }
    },
    minute: {
      handler (newVal) {
        this.num(newVal)
      }
    },
    hour: {
      handler (newVal) {
        this.num(newVal)
      }
    },
    QRCode (newName, oldName) {
      if (newName === false) {
        clearInterval(this.timer)
      }
    },
    immediate: true,
    deep: true
  },
  computed: {
    rowSelection () {
      return {
        selectedRowKeys: this.selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
          this.selectedRowKeys = selectedRowKeys
          this.selectedRows = selectedRows
        },
        getCheckboxProps: (record) => ({
          props: {
            disabled: record.status == 1
          }
        })
      }
    }
  },
  created () {
    this.handleGetTypeList()
  },
  methods: {
    handleGetTypeList () {
      getTypeDetail({ dictName: 'data_evaluate_tab' }).then((res) => {
        this.typeList = res.data
      })
    },
    fatherMethod () {
      this.$refs.table.refresh()
    },
    handleTypeClick (key) {
      if (this.typeSelect.length < 5) {
        if (this.typeSelect.includes(key)) {
          this.typeSelect = this.typeSelect.filter((item) => item !== key)
        } else {
          this.typeSelect.push(key)
        }
      } else {
        if (this.typeSelect.includes(key)) {
          this.typeSelect = this.typeSelect.filter((item) => item !== key)
        }
        if (this.typeSelect.length == 5) {
          this.$message.error('标签最多可选5个')
        }
      }
    },
    handleGetData (params) {
      this.selectedRowKeys = []
      this.selectedRows = []

      return getMyDataOrder(this.handleGetParams(params))
    },
    handleGetParams (params) {
      if (this.val == 1) {
        return {
          ...params
        }
      } else if (this.val == 2) {
        return {
          ...params,
          status: 0
        }
      }
      return {
        ...params,
        evaluateStatus: 0, // 评价状态  status
        status: 1 // 订单状态  orderStatus
      }
    },
    Evaluate (row) {
      this.row = row
      this.rate = 5
      this.evaluation = ''
      this.isEvaluate = true
    },
    getTab (val) {
      if (val != this.val) {
        this.val = val
        this.$refs.table.initPage()
      }
    },
    detail (row) {
      this.visible = true
    },
    handleOk () {
      if (this.rate === 0) {
        this.$message.warning('请选择评分')
      } else {
        const typeList = this.typeList
          .filter((item) => this.typeSelect.some((key) => item.id === key))
          .map((item) => item.label)
        const model = {
          ...this.row,
          content: this.evaluation,
          tab: typeList.join(','),
          score: this.rate,
          evaluateStatus: 1
        }
        updateEvaluation(model).then(() => {
          this.$message.success('评价成功')
          this.rate = ''
          this.evaluation = ''
          this.isEvaluate = false
          this.typeSelect = []
          this.$refs.table.refresh()
        })
      }
    },
    handleOk1 () {
      this.isEvaluate1 = false
    },
    handleCancel1 () {
      this.isEvaluate1 = false
    },
    handleCancel () {
      this.evaluation = ''
      this.typeSelect = []
      this.rate = ''
    },
    handleWeChatPay (outTradeNo, createdTime) {
      const that = this
      that.$refs.payZf.handleWeChatPay(outTradeNo, createdTime)
    },
    getEvaluateDetail (row) {
      this.disabled = true
      this.typeList1 = []
      this.rate = ''
      getEvaluationDetail(row.evaluationId).then((res) => {
        this.rate = res.data.score
        this.evaluation = res.data.content
        this.isEvaluate1 = true
        if (res.data.tab) {
          const h = res.data.tab.split(',')
          this.typeList1 = h
        }
      })
    }
  },
  destroyed () {
    clearInterval(this.timer)
    this.timer = null
  }
}
</script>

<style lang="less">
@import url('../../../common/style');
@import url('../../recruitUse/recruit/index');
@import url('../../recruitUse/evaluate/index');
.material__order__wrapper {
  height: 100%;
  .content {
    height: calc(100% - 45px);
    padding: 24px;
  }
  .custom_cell {
    display: flex;
    align-items: center;
    width: 100%;
    .custom {
      display: flex;
      align-items: center;
    }
    .left {
      margin-right: 5px;
    }
    img {
      height: 30px;
      width: 30px;
    }
    .right {
      text-align: left;
    }
  }
}
.div_zf {
  cursor: pointer;
  width: 220px;
  height: 100px;
  border: 1px solid black;
  display: inline-block;
  position: relative;
  .div_zf_img {
    width: 200px;
    height: 70px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
  }
}
.vueQr {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}
</style>
