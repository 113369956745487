import request from '@/utils/request'

// 分页多条件查询我的订单
export function getMyOrder (parameter) {
    return request({
      url: '/api/enterprise/employmentOrder/enterpriseOrderController/query',
      method: 'get',
      params: {
          ...parameter
      }
    })
  }

// 分页查询资料订单
export function getMyDataOrder (parameter) {
  return request({
    url: '/api/enterprise/employmentOrder/enterpriseOrderController/queryDataOrder',
    method: 'get',
    params: {
        ...parameter
    }
  })
}

// 修改资料评价
export function updateEvaluation (updateCmd) {
  return request({
    url: '/api/enterprise/employmentEvaluate/enterpriseDataEvaluationController',
    method: 'put',
    data: updateCmd
  })
}

// 查询资料评价详情
export function getEvaluationDetail (id) {
  return request({
    url: `/api/enterprise/employmentEvaluate/enterpriseDataEvaluationController/${id}`,
    method: 'get'
  })
}
